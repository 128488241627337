import { nextTick } from "vue";

import { createRouter, createWebHistory } from 'vue-router';

import { useCookies } from "vue3-cookies";

import { capturePosthogPageview } from "@ui-lib";


const routes = [
    {
        path: '/',
        name: 'Home',
        component: () =>
            import('@/views/Home.vue')
    },
    {
        path: '/contact/demo',
        name: 'Demo',
        component: () =>
            import ('@/views/contact/Demo.vue')
    },
    {
        path: '/features/privacy',
        name: 'FeaturePrivacy',
        component: () =>
            import ('@/views/features/Privacy.vue')
    },
    {
        path: '/products/live-training',
        name: 'LiveTraining',
        component: () =>
            import('@/views/products/LiveTraining.vue')
    },
    {
        path: '/products/meeting-reviews',
        name: 'MeetingReviews',
        component: () =>
            import('@/views/products/MeetingReviews.vue')
    },
    {
        path: '/legal/privacy',
        name: 'PrivacyPolicy',
        component: () =>
            import('@/views/legal/Privacy.vue')
    },
    {
        path: '/legal/service',
        name: 'ServiceAgreement',
        component: () =>
            import('@/views/legal/Service.vue')
    },
    {
        path: '/legal/terms',
        name: 'TermsOfService',
        component: () =>
            import('@/views/legal/Terms.vue')
    },
    {
        path: '/zoom/documentation',
        name: 'ZoomDocumentation',
        component: () =>
            import('@/views/zoom/Documentation.vue')
    },
    {
        path: '/zoom/support',
        name: 'ZoomSupport',
        component: () =>
            import('@/views/zoom/Support.vue'),
    },

    // Redirects
    {
        path: '/features/goals',
        redirect: () => {
            return { name: 'Home' }
        },
    },
    {
        path: '/features/reporting',
        redirect: () => {
            return { name: 'Home' }
        },
    },
    {
        path: '/features/training',
        redirect: () => {
            return { name: 'Home' }
        },
    },
    {
        path: '/pricing',
        redirect: () => {
            return { name: 'Home' }
        },
    },
    {
        path: '/privacy',
        redirect: () => {
            return { name: 'PrivacyPolicy' }
        },
    },
    {
        path: '/support',
        redirect: () => {
            return { name: 'ZoomSupport' }
        },
    },
    {
        path: '/terms',
        redirect: () => {
            return { name: 'TermsOfService' }
        },
    },

    // Default
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    },
];

const history = createWebHistory();

const router = createRouter({
    history,
    routes,
    scrollBehavior() {
        return {top: 0};
    },
});

router.beforeEach((to) => {
    const emailCampaignKey = import.meta.env.VITE_EMAIL_CAMPAIGN_KEY
    if (emailCampaignKey in to.query && to.query[emailCampaignKey] !== null) {
        const cookies = useCookies()
        cookies.cookies.set(emailCampaignKey, <string>to.query[emailCampaignKey])
    }
})

// Capture all Page Views
router.afterEach((to, from, failure) => {
    if (!failure) {
        nextTick(() => {
            capturePosthogPageview(to.fullPath);
        });
    }
});

export default router;
